import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from '../_services/utils.service';
import { Router } from '@angular/router';
import { BaseUrlService } from '../_services/base-url.service';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import { GuiSearching, GuiSorting, GuiColumn, GuiGridComponent, GuiGridApi } from '@generic-ui/ngx-grid';

@Component({
  selector: 'app-api-search-details',
  templateUrl: './api-search-details.component.html',
  styleUrls: ['./api-search-details.component.scss']
})
export class ApiSearchDetailsComponent  {
  @ViewChild('apiModal') apiModal: any;
  @ViewChild('grid', { static: true }) grid: any;
  isLoading = false;
  data: any;
  apiMappings: any;
  searchTable: any = [];
  showConfiguration: boolean = false;
  utilService: any;
  api: any;
  modelFound = {
    odsTran: '',
    odsType: '',
    api: '',
    searchType: '',
    inputName: ''
  }
  searching: GuiSearching = {
    enabled: true,
    placeholder: 'Filter...'
  };
  sorting: GuiSorting = {
    enabled: true,
    multiSorting: true
  };
  gridOptions: Array<GuiColumn> = [
      {header: 'ODS FIELD NAME', field: 'fieldName'},
      {header: 'API FIELD NAME', field: 'apiFieldName'},
      {header: 'BUSINESS NAME', field: 'businessName'},
      {header: 'DESCRIPTION', field: 'description'},
      {header: 'FIELD LENGTH', field: 'fieldLength'},
      {header: 'DEC POS', field: 'decimalPos'},
      {header: 'FILE NAME', field: 'fileNameId'},
      {header: 'FILE OFFSET', field: 'fileOffsetDec'},
      {header: 'DATA FORMAT', field: 'dateFormat'},
      {header: 'DATA TYPE', field: 'dataType'},
    ]
  rowData: any = [];
  httpOptions: any

  constructor(private modalService: NgbModal, utilService: UtilsService, private router: Router, private baseUrlService: BaseUrlService, private http: HttpClient) {
    this.utilService = utilService;
  }

  ngAfterViewInit(): void {
      this.fetchRowData();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.modelFound.searchType = sessionStorage.getItem('searchType') ?? '';
    this.modelFound.inputName = sessionStorage.getItem('inputName') ?? '';
    this.modelFound.odsTran = sessionStorage.getItem('odsTran') ?? '';
    if(this.modelFound.odsTran === null) {
      this.router.navigate(['NA/search']);
    }
    this.api = sessionStorage.getItem('api');
    this.modelFound.odsType = sessionStorage.getItem('odsType') ?? '';
    this.modelFound.api = sessionStorage.getItem('api') ?? '';
    const token = this.baseUrlService.getToken();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token
      })
    };
  }

  fetchRowData() {
    const body = {
      odsTran: this.modelFound.odsTran,
      apiName: this.api,
    };
    this.http.post(this.baseUrlService.getBaseURL() + '/api/fs/devportal/v1/searchFieldsByOdsTran',
      body, this.httpOptions
    )
      .subscribe(
        data => {
          this.updateTableResults(data);
        },
        error => {
          console.log('Error', error);
          const errDiv = document.getElementById('create-error');
          if (errDiv) {
            errDiv.innerText = error.message;
            this.searchTable = [];
            this.rowData = [];
          }
        }
      );
  }

  updateTableResults(data: any) {
    this.data = data;
    this.showConfiguration = true;
    this.searchTable = this.data.results;
    this.isLoading = false;
    if (this.searchTable !== undefined) {
      for (const item of this.searchTable) {
        const row = {
          apiFieldName: '',
          businessName: '',
          description: '',
          validValues: '',
          fieldLength: '',
          decimalPos: '',
          fieldName: '',
          fileOffsetDec: '',
          dateFormat: '',
          dataType: '',
          fileNameId: ''
        };
        for (const [key, value] of Object.entries(item)) {
          row[key as keyof typeof row] = value as string;

        }
        this.rowData.push(row);
        console.log(this.rowData);
        this.rowData = [...this.rowData];
      }
    }
  }

  abValueGetter(params: any) {
    if (params.data.odsType === 'RPC') {
      return params.data.fileName;
    } else {
      return params.data.fileName + '(' + params.data.fileId + ')';
    }
  }

  nullValueGetter(params: any) {
    if (params.data.fileOffsetDec.indexOf('null') > -1) {
      return '';
    } else {
      return params.data.fileOffsetDec;
    }
  }

  openApiModal(content: any) {
    this.modalService.open(content, { size: 'xl', scrollable: true });
  }

  back() {
    window.history.back();
  }

  goToDevStudioSwagger(apiName: any) {
    const pathName = "/product/IssuerSolutions/api/?type=post&path=" + apiName;
    this.utilService.navigateToPage(pathName);
  }

  swaggerLink() {
    this.goToDevStudioSwagger(this.modelFound.api);
  }

  onSelectedRows = (event: any) => {
    this.goToDevStudioSwagger(this.api);
  }
}
