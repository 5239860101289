import {Component, OnInit, AfterViewInit} from '@angular/core';
import {UtilsService} from '../_services/utils.service';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BaseUrlService} from '../_services/base-url.service';
import { GuiSearching, GuiSorting, GuiColumn } from '@generic-ui/ngx-grid';

@Component({
  selector: 'app-file-search',
  templateUrl: './file-search.component.html',
  styleUrls: ['./file-search.component.scss']
})
export class FileSearchComponent implements OnInit {
  utilService: any;
  rowData: any = [];
  token: any;
  searchTable: any = [];
  dataForExcel = [];
  searching: GuiSearching = {
    enabled: true,
    placeholder: 'Filter...'
  };
  sorting: GuiSorting = {
    enabled: true,
    multiSorting: true
  };
  sampleGridOptions: Array<GuiColumn> = [
    {header: 'NAME', field: 'fileName', width: 300},
    {header: 'NUMBER', field: 'fileNumber', width: 150},
    {header: 'LENGTH', field: 'fileLength', width: 150},
    {header: 'DESCRIPTION', field: 'description', width: 1000}
  ];

  constructor(utilService: UtilsService, private router: Router, private baseUrlService: BaseUrlService, private http: HttpClient) {
    this.utilService = utilService;
  }

  ngAfterViewInit(): void {
      this.fetchRowData();
  }

  ngOnInit(): void {
    this.utilService.updateMetaValues();
  }

  fetchRowData() {
    this.token = this.baseUrlService.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.token
      })
    };

    const body = {};
    this.http.post(this.baseUrlService.getBaseURL() + '/api/fs/devportal/v1/findBatchFiles',
        body, httpOptions
      )
      .subscribe(
        data => {
          this.searchTable = data;
          this.searchTable = this.searchTable.results;
          if (this.searchTable !== undefined) {
          this.searchTable.sort((a: any, b: any) => (a.fileName > b.fileName) ? 1 : -1)
          if (this.searchTable !== undefined) {
            for (const item of this.searchTable ) {
              this.rowData.push(item);
            }
          this.rowData = [...this.rowData];
          }
        }
        },
      )
  }

  onSelectedRows = (event: any) => {
    sessionStorage.setItem('fileNumber', event[0].source.fileNumber);
    sessionStorage.setItem('description', event[0].source.description);
    sessionStorage.setItem('fileName', event[0].source.fileName);
    this.router.navigate(['file-search-details'])
    const env = this.baseUrlService.getEnvironment();
    this.router.navigate(['NA/file-search-details']);
  }
}
