import {Component} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseUrlService } from '../_services/base-url.service';
import { UtilsService } from '../_services/utils.service';
import { GuiSearching, GuiSorting, GuiColumn } from '@generic-ui/ngx-grid';

@Component({
  selector: 'app-event-search',
  templateUrl: './event-search.component.html',
  styleUrls: ['./event-search.component.scss']
})
export class EventSearchComponent {
  util: any;
  rowData: any = [];
  rowDataCopy: any = [];
  token: any;
  searchTable: any = [];
  data: any;
  fileName = 'reportEventSearch.csv';
  searching: GuiSearching = {
    enabled: true,
    placeholder: 'Filter...'
  };
  sorting: GuiSorting = {
    enabled: true,
    multiSorting: true
  };
  gridColumns: Array<GuiColumn> = [
    {header: 'API FIELD NAME', field: 'apiFieldName'},
    {header: 'BUSINESS NAME', field: 'businessName'},
    {header: 'DESCRIPTION', field: 'description'},
    {header: 'EVENT TYPE', field: 'eventType'},
    {header: 'DATA LENGTH', field: 'dataLength'},
    {header: 'COPYBOOK FIELD', field: 'copyBookFieldName'},
    {header: 'COPYBOOK', field: 'copyBooks'},
    {header: 'SNOWFLAKE LOCATION', field: 'snowflakeLocation'},
    {header: 'TRIGGER EVENT', field: 'triggerEvent'},
    {header: 'TRIGGER VALUES', field: 'triggerValues'}
  ];
  constructor(private utilsService: UtilsService, private http: HttpClient, private baseUrlService: BaseUrlService) {
    this.util = utilsService;
  }

  ngAfterViewInit(): void {
      this.fetchRowData();
  }

  ngOnInit(): void {
    this.utilsService.updateMetaValues();
  }

  fetchRowData() {
    this.token = this.baseUrlService.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.token,
      }),
    };

    const body = {};
    this.http
      .post(
        this.baseUrlService.getBaseURL() + '/api/fs/devportal/v1/allPubsubData',
        body,
        httpOptions
      )
      .subscribe(
        (data) => {
          this.searchTable = data;
          this.searchTable = this.searchTable.results;
          if (this.searchTable !== undefined) {
            for (const item of this.searchTable) {
              this.rowData.push(item);
            }
            this.rowDataCopy = [...this.rowData];
            this.rowData = this.rowDataCopy;
          }
        }
      );
  }

  onBtExport() {
    const csvContent = this.convertToCsv(this.gridColumns, this.rowData);
    this.downloadCsv(csvContent, this.fileName);
  }

  convertToCsv(columns: Array<GuiColumn>, data: any): string {
    const headers = columns.map(column => column.header).join(',');
    const rows = data.map(item => Object.values(item).join(',')).join('\n');
    return `${headers}\n${rows}`;
  }

  downloadCsv(csvContent: string, fileName: string): void {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
