// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  tokenEnvLocal : 'eyJhbGciOiJIUzI1NiJ9.eyJhcHBJZCI6ImRldlBvcnRhbCIsIng1MDBJZCI6IkFBQUExMTE0IiwiZ3JvdXBzIjpbIkRFVkVMT1BFUi1QT1JUQUwiXSwiaXNzIjoiTlNBIiwiZXhwIjo0MDk5MTU5MDc0LCJuYmYiOjE1OTkxNTgxNzQsImp0aSI6IjFkMDY3MDIyLTgzZjEtNDRjMC04NjcwLTIyODNmMWY0YzA1YiJ9.AfYsCO3kcdexr2Y5IZyeWVw7mCNQVMoKOtGYqGMq-_g',
  tokenEnvQA : 'eyJhbGciOiJIUzI1NiJ9.eyJhcHBJZCI6ImRldlBvcnRhbCIsIng1MDBJZCI6IkFBQUExMTE0IiwiZ3JvdXBzIjpbIkRFVkVMT1BFUi1QT1JUQUwiXSwiaXNzIjoiTlNBIiwiZXhwIjo0MDk5MTU5MDc0LCJuYmYiOjE1OTkxNTgxNzQsImp0aSI6IjFkMDY3MDIyLTgzZjEtNDRjMC04NjcwLTIyODNmMWY0YzA1YiJ9.AfYsCO3kcdexr2Y5IZyeWVw7mCNQVMoKOtGYqGMq-_g',
  tokenEnvCAT : 'eyJhbGciOiJIUzI1NiJ9.eyJhcHBJZCI6ImRldlBvcnRhbCIsIng1MDBJZCI6IkFBQUExMTE0IiwiZ3JvdXBzIjpbIkRFVkVMT1BFUi1QT1JUQUwiXSwiaXNzIjoiTlNBIiwiZXhwIjo0MDk5MTU5MDc0LCJuYmYiOjE1OTkxNTgxNzQsImp0aSI6IjFkMDY3MDIyLTgzZjEtNDRjMC04NjcwLTIyODNmMWY0YzA1YiJ9.IvZnkj_71CNM2ZHyiCg1QBs0JELrk7bGPXzssBy9UWA',
  tokenEnv : 'eyJhbGciOiJIUzI1NiJ9.eyJhcHBJZCI6ImRldlBvcnRhbCIsIng1MDBJZCI6IkFBQUExMTE0IiwiZ3JvdXBzIjpbIkRFVkVMT1BFUi1QT1JUQUwiXSwiaXNzIjoiTlNBIiwiZXhwIjo0MDk5MTU5MDc0LCJuYmYiOjE1OTkxNTgxNzQsImp0aSI6IjFkMDY3MDIyLTgzZjEtNDRjMC04NjcwLTIyODNmMWY0YzA1YiJ9.4-sdLuOlefbHiG8RFKdbUKX2sCV60jpdpGMssm667qw'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
