<div class="container-fluid pt-4 ps-4">
    <div id="page-content-wrapper" class="search-results">
        <div class="row mb-5">
            <div class="col col-sm-3">

                <div class="card">
                    <div class="card-header">
                        Original Search
                    </div>
                    <div class="card-body">
                        {{modelFound.searchType}} | {{modelFound.inputName}}
                    </div>
                </div>
            </div>

            <div class="col col-sm-3">
                <div class="card">
                    <div class="card-header">
                        Open Data Streams (ODS) Transaction
                    </div>
                    <div class="card-body text-dark">
                        {{modelFound.odsTran}}
                    </div>
                </div>
            </div>

            <div class="col col-sm-3">
                <div class="card">
                    <div class="card-header">
                        ODS Type
                    </div>
                    <div class="card-body text-dark">
                        {{modelFound.odsType}}
                    </div>
                </div>
            </div>

            <div class="col col-sm-3">
                <div class="card">
                    <div class="card-header">
                        RESTful Web Service (API)
                    </div>
                    <div class="card-body text-dark">
                        <a class="link-primary" (click)="swaggerLink()">{{modelFound.api}}</a>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="row mt-3 mb-3">
        <div class="col col-sm-12 mt-2 mb-4">
            <gui-grid #grid
                      [source]="rowData"
                      [columns]="gridOptions"
                      [virtualScroll]="true"
                      [maxHeight]="410"
                      [searching]="searching"
                      [sorting]="sorting"
                      (selectedRows)='onSelectedRows($event)'>
            </gui-grid>
        </div>
    </div>

    <div class="row mt-3 mb-5">
        <div class="mb-4 ml-3">
            <button class="btn btn-outline-secondary" (click)="back()">Back</button>
        </div>
    </div>

</div>

<ng-template #apiModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Modal title</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>
