import {Component,OnInit} from '@angular/core';
import {UtilsService} from '../_services/utils.service';
import {Router} from '@angular/router';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {BaseUrlService} from '../_services/base-url.service';
import { GuiSearching, GuiSorting, GuiColumn } from '@generic-ui/ngx-grid';

@Component({
  selector: 'app-file-search-details',
  templateUrl: './file-search-details.component.html',
  styleUrls: ['./file-search-details.component.scss']
})
export class FileSearchDetailsComponent implements OnInit {
  modelFound = {
    fileNumber: '',
    description: '',
    fileName: ''
  };
  token: any;
  utilService: any;
  searchTable: any = [];
  rowData: any = [];
  searching: GuiSearching = {
    enabled: true,
    placeholder: 'Filter...'
  };
  sorting: GuiSorting = {
    enabled: true,
    multiSorting: true
  };
  gridOptions: Array<GuiColumn> = [
    {header: 'FIELD NAME', field: 'fieldName'},
    {header: 'BUSINESS NAME', field: 'businessName'},
    {header: 'DESCRIPTION', field: 'description'},
    {header: 'FIELD VALUES', field: 'fieldValues'},
    {header: 'BEGIN', field: 'beginPos'},
    {header: 'LENGTH', field: 'length'},
    {header: 'FORMAT', field: 'format'},
  ];

  constructor(utilService: UtilsService, private router: Router, private http: HttpClient, private baseUrlService: BaseUrlService) {
    this.utilService = utilService;
  }

  ngAfterViewInit(): void {
      this.fetchRowData();
  }

  ngOnInit(): void {
    const fileNumber = sessionStorage.getItem('fileNumber');
    this.modelFound.fileNumber = fileNumber !== null ? fileNumber : '';
    const description = sessionStorage.getItem('description');
    this.modelFound.description = description !== null ? description : '';
    const fileName = sessionStorage.getItem('fileName');
    this.modelFound.fileName = fileName !== null ? fileName : '';
  }

  fetchRowData() {
    this.token = this.baseUrlService.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.token
      })
    };

    const body = {
      fileNumber: this.modelFound.fileNumber
    };
    this.http.post(this.baseUrlService.getBaseURL() + '/api/fs/devportal/v1/findBatchFileById',
        body, httpOptions
       )
      .subscribe(
        data => {
          this.searchTable = data;
          this.searchTable = this.searchTable.results;
          if (this.searchTable !== undefined) {
            this.searchTable.sort((a: any, b: any) => (a.beginPos > b.beginPos) ? 1 : (a.beginPos === b.beginPos) ? ((a.length < b.length) ? 1 : -1) : -1);
            for (const item of this.searchTable) {
              this.rowData.push(item);
            }
            this.rowData = [...this.rowData];
          }
        }
      )
  }

  back() {
    window.history.back();
  }
}
