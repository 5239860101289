import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../_services/utils.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseUrlService } from '../_services/base-url.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { GuiSearching, GuiSorting, GuiColumn, GuiGridComponent, GuiGridApi } from '@generic-ui/ngx-grid';

@Component({
  selector: 'app-api-search',
  templateUrl: './api-search.component.html',
  styleUrls: ['./api-search.component.scss']
})
export class ApiSearchComponent implements OnInit{
  token: any = '';
  data1: any = [];
  autoComplete: any;
  utilService: any;
  data: any = [];
  showConfiguration: boolean = false;
  httpOptions: any = {};
  token1: any;
  item: any;
  model = {
    searchType: 'API',
    inputName: '',
    somePlaceholder: 'Ex. "/account/v4/accountRelationships"',
    orginialSearch: ''
  };
  searchTable: any = [];
  dataTable: any = [];
  dataLoad: any = [];
  autoCompleteMinCharFilterLimit = 3;
  excludeFromResponse: boolean;
  searching: GuiSearching = {
    enabled: true,
    placeholder: 'Filter...'
  };
  sorting: GuiSorting = {
    enabled: true,
    multiSorting: true
  };
  gridOptions: Array<GuiColumn> = [
    {header: 'LIFECYCLE', field: 'lifecycle'},
    {header: 'CATEGORY', field: 'category'},
    {header: 'ODS TRANSACTION NAME', field: 'odsTran'},
    {header: 'ODS TYPE', field: 'odsType'},
    {header: 'ODS DEFINITION', field: 'odsDefinition'},
    {header: 'API NAME', field: 'api'},
    {header: '3270 FUNCTION', field: 'x3270Function'},
    {header: 'OCS SECURITY ALIAS', field: 'securityAlias'},
  ]
  rowData: any = [];
  constructor(utilService: UtilsService, private router: Router, private http: HttpClient, private baseUrlService: BaseUrlService) {
    this.excludeFromResponse = false;
    this.utilService = utilService;
  }

  ngOnInit() {
    this.token = this.baseUrlService.getToken();
    this.excludeFromResponse = this.baseUrlService.includeHiddenApis();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.token
      })
    };

    const body = {excludeFromResponse: this.excludeFromResponse,};
    if (sessionStorage.getItem('autoCompleteList1') == null) {
        this.http.post(this.baseUrlService.getBaseURL() + '/api/fs/devportal/v1/autoComplete',
              body, httpOptions
            )
              .subscribe(
                data => {
                  this.data1 = data;
                  this.dataLoad = this.data1;
                  this.dataTable = this.dataLoad.apis
                  sessionStorage.setItem('autoCompleteList1', JSON.stringify(data));
                },
                error => {
                  const errDiv = document.getElementById('create-error');
                  if (errDiv) {
                    errDiv.innerText = error.message;
                  }
                }
              )
              .add(() => {
               this.restoreSessionSearch();
              });
    }
    else {
        const autoCompleteList1 = sessionStorage.getItem('autoCompleteList1');
        this.data1 = autoCompleteList1 !== null ? JSON.parse(autoCompleteList1) : null;
        this.dataLoad = this.data1;
        this.dataTable = this.dataLoad.apis
        this.restoreSessionSearch();
    }
  }

  placeHolderChange() {
    const filterBox = document.getElementById('filter-text-box');
    if(filterBox) {filterBox.style.borderColor = '#ced4da'}
    if (this.model.searchType === 'API') {
      this.model.somePlaceholder = 'Ex. "/account/v4/accountRelationships"';
      this.model.inputName = '';
      this.autoCompleteMinCharFilterLimit = 3;
      this.dataTable = this.dataLoad.apis
    }
    else if (this.model.searchType === 'ODS_TRAN') {
      this.model.somePlaceholder = 'Ex. "ACCOUNT_RELATIONSHIPS"';
      this.model.inputName = '';
      this.autoCompleteMinCharFilterLimit = 2;
      this.dataTable = this.dataLoad.odsTransactions
    }
    else if (this.model.searchType === 'X3270_FUNC') {
      this.model.somePlaceholder = 'Ex. "CAI"';
      this.model.inputName = '';
      this.dataTable = [];
    }
    else if (this.model.searchType === 'CATEGORY') {
      this.model.somePlaceholder = 'Ex. "Account"';
      this.model.inputName = '';
      this.autoCompleteMinCharFilterLimit = 3;
      this.dataTable = this.dataLoad.categories
    }
    else if (this.model.searchType === 'WILDCARD') {
      this.model.somePlaceholder = 'Ex. "Balance"'
      this.model.inputName = '';
      this.dataTable = [];
    }

    return this.dataTable;
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => {
        return term.length < this.autoCompleteMinCharFilterLimit
          ? []
          : this.dataTable
            .filter((v: any) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
            .slice(0, 10)
      })
    )

  selectedItem(item: any){
    this.model.inputName = item.item
    const searchButton = document.getElementById('searchBtn');
    if(searchButton) {searchButton.click()}
  }

  onFilterTextBoxChanged() {
    this.model.inputName = '';
    const searchButton = document.getElementById('searchBtn');
    if(searchButton) {searchButton.click()}
    const filterBox = document.getElementById('filter-text-box');
    if(filterBox) {filterBox.style.borderColor = '#ced4da'}
  }

  postToSearchApi = (body: any, httpOptions: any, currentTarget: any, enableFilterApiBySearchTerm = false) => {

    this.http.post(this.baseUrlService.getBaseURL() + '/api/fs/devportal/v1/searchApi',
      body, httpOptions
    )
      .subscribe(
        data => {
          this.data = data;
          this.showConfiguration = true;
          this.searchTable = this.data.results;
          if(enableFilterApiBySearchTerm && this.model.searchType === 'API' && this.searchTable !== undefined ) {
            this.searchTable = this.data.results.filter((item: any) => item.item.odsTran.indexOf(this.model.inputName.toUpperCase()) > -1); }
            else if(enableFilterApiBySearchTerm && this.model.searchType === 'ODS_TRAN' && this.searchTable !== undefined  ) {
              this.searchTable = this.data.results.filter((item: any) => item.odsTran.indexOf(this.model.inputName.toUpperCase()) > -1); }
            else { this.searchTable = this.data.results;
            }
           if (this.searchTable !== undefined) {
            for (const item of this.searchTable) {
              const row = {
                lifecycle: '',
                category: '',
                function: '',
                odsTran: '',
                odsType: '',
                odsDefinition: '',
                api: '',
                x3270Function: '',
                accessMethod: '',
                securityAlias: ''
              };
              for (const [key, value] of Object.entries(item)) {
                row[key as keyof typeof row] = value as string;

              }
              this.rowData.push(row);
              this.rowData = [...this.rowData];
            }
          }
        },
        error => this.genericSubmitError(error)
      ).add(() => {
        currentTarget.disabled = false;
      });
  }

  genericSubmitError(error: any) {
    console.log('Error', error);
    const errDiv = document.getElementById('create-error');
    if (errDiv) {
      errDiv.innerText = error.message;
      this.searchTable = [];
      this.rowData = [];
    }
  }

  submit($event: any) {
    $event.currentTarget.disabled = true;
    const filterBox = document.getElementById('filter-text-box');
    if(filterBox) {filterBox.style.borderColor = '#ced4da'}
    sessionStorage.setItem('inputName', this.model.inputName);
    this.rowData = [];
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.token
      })
    };

    if (this.model.inputName === '') {
      $event.currentTarget.disabled = false;
      const filterBox = document.getElementById('filter-text-box');
      if(filterBox) {filterBox.style.borderColor = 'red'}
      return;
    }

    switch (this.model.searchType) {
      case 'ODS_TRAN': {
        this.model.orginialSearch = 'Open Data Streams (ODS) Transaction Names';
        const body = {
          searchType: this.model.searchType,
          odsTran: this.model.inputName.toUpperCase(),
          excludeFromResponse: this.excludeFromResponse,
        };
        if(this.model.inputName.toUpperCase() !== this.model.inputName.toLowerCase()) {
          this.postToSearchApi(body, httpOptions, $event.currentTarget);
      } else {
          //WILDCARD
          const body = {
            searchType: 'WILDCARD',
            wildCard: this.model.inputName.toUpperCase(),
            excludeFromResponse: this.excludeFromResponse,
          };
          this.postToSearchApi(body, httpOptions, $event.currentTarget, true);
      }
        break;
      }
      case 'CATEGORY': {
        this.model.orginialSearch = 'RESTful Web Services (API) Category Names';
        const body = {
          searchType: this.model.searchType,
          category: this.matchAutoComplete(this.model.inputName),
          excludeFromResponse: this.excludeFromResponse,
        };

        this.postToSearchApi(body, httpOptions, $event.currentTarget);
        break;
      }
      case 'X3270_FUNC': {
        this.model.orginialSearch = '3270 Screen/Function Names';
        const body = {
          searchType: this.model.searchType,
          x3270Func: this.model.inputName,
          excludeFromResponse: this.excludeFromResponse,
        };

        this.postToSearchApi(body, httpOptions, $event.currentTarget);
        break;
      }
      case 'WILDCARD': {
        this.model.orginialSearch = 'Comprehensive General Purpose Names';
        const body = {
          searchType: this.model.searchType,
          wildCard: this.model.inputName,
          excludeFromResponse: this.excludeFromResponse,
        };

        this.postToSearchApi(body, httpOptions, $event.currentTarget);
        break;
      }
      default: {
        // API
        this.model.orginialSearch = 'RESTful Web Services (API) Service Names';
        const body = {
          searchType: this.model.searchType,
          apiName: this.matchAutoComplete(this.model.inputName),
          excludeFromResponse: this.excludeFromResponse,
        };
        if(this.model.inputName.indexOf('/') > -1) {
            this.postToSearchApi(body, httpOptions, $event.currentTarget);
        } else {
            //WILDCARD
            const body = {
              searchType: 'WILDCARD',
              wildCard: this.model.inputName,
              excludeFromResponse: this.excludeFromResponse,
            };
            this.postToSearchApi(body, httpOptions, $event.currentTarget, true);
        }
        break;
      }
    }
    sessionStorage.setItem('searchType', this.model.orginialSearch);
    sessionStorage.setItem('searchTypeSelection', this.model.searchType);
  }

  restoreSessionSearch() {
    const searchType = sessionStorage.getItem('searchTypeSelection')
    const inputValue = sessionStorage.getItem('inputName')
    if (searchType && inputValue) {
        this.model.searchType = searchType
        this.model.inputName = inputValue

        if (searchType === 'API') {
        } else if (searchType === 'ODS_TRAN') {
            this.dataTable = this.dataLoad.odsTransactions
        } else if (searchType === 'CATEGORY') {
            this.dataTable = this.dataLoad.categories
        } else {
            this.dataTable = []
        }

        const searchButton = document.getElementById('searchBtn');
        if(searchButton) {searchButton.click()}
    }
  }
  matchAutoComplete(inputText: any) {
    const searchText = this.dataTable.find((x: any) => x.toUpperCase() === inputText.toUpperCase());
    return searchText !== undefined ? searchText : inputText;
  }

  onSelectedRows = (event: any) => {
    sessionStorage.setItem('odsTran', event[0].source.odsTran);
    sessionStorage.setItem('api', event[0].source.api);
    sessionStorage.setItem('odsType', event[0].source.odsType);
    this.router.navigate(['NA/search-results'])
  }
}
