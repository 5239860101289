<div class="main_container">
    <div class="search-header-section border-bottom">
        <app-toolbar></app-toolbar>
    </div>
    <div class="container-fluid pt-4 ps-4 bg-white">
        <div class="row">
            <div class="col">
                <ul class="breadcrumb bg-white fiserv-orange-color">
                    <li class="fiservBreadcrumbs"><a (click)="this.util.navigateToPage('')">Home</a></li>
                    <li class="fiservBreadcrumbs">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" class="breadcrumbsArrow" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>
                        <a (click)="this.util.navigateToPage('/financial-institutions')">Financial Institutions</a>
                    </li>
                    <li class="fiservBreadcrumbs">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" class="breadcrumbsArrow" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>
                        <a (click)="this.util.navigateToPage('/product/IssuerSolutions')">Issuer Solutions</a></li>
                    <li class="color-grey-disable fiservBreadcrumbs">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" class="breadcrumbsArrow" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>
                        <span>Event Search</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <h4 class="col col-sm-9 mb-4 mt-3">Event Search</h4>
            </div>
            <div class="col-md-6 text-end mt-3">
                <button class="btn btn-outline-secondary" (click)="onBtExport()">Download</button>
            </div>
        </div>
        <form #clientForm="ngForm">
            <div class="row mt-3 mb-5">
                <div class="col col-sm-12 mb-5">
                    <gui-grid
                            #grid
                            [source]="rowData"
                            [columns]="gridColumns"
                            [virtualScroll]="true"
                            [maxHeight]="410"
                            [searching]="searching"
                            [sorting]="sorting">
                    </gui-grid>
                </div>
            </div>
        </form>
    </div>
</div>
