<div id="main_container">
    <div class="search-header-section">
        <app-toolbar></app-toolbar>
    </div>
    <div class="api-browser-content-container" id="api-browser-content">
        <div class="api-browser-grid-container">
            <app-api-search-details></app-api-search-details>
        </div>
    </div>
</div>
