<div class="main_container">
    <div class="search-header-section">

        <app-toolbar></app-toolbar>

    </div>
    <div class="container-fluid pt-3 bg-white">
        <div id="page-content-wrapper" class="search-results">
            <div class="row mt-3 mb-5">
                <div class="col col-sm-12">

                    <div class="card w-auto p-3">

                        <div class="card-header card-block text-center">
                            {{modelFound.fileName}} ({{modelFound.fileNumber}})
                        </div>

                        <div class="card-body">
                            {{modelFound.description}}
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="row mt-3 mb-5">
            <div class="col col-sm-12 mt-2 mb-4">
                <gui-grid
                        [source]="rowData"
                        [columns]="gridOptions"
                        [virtualScroll]="true"
                        [maxHeight]="410"
                        [searching]="searching"
                        [sorting]="sorting">
                </gui-grid>
            </div>
        </div>

        <div class="row mt-3 mb-5">
            <div class="col col-sm-3 mb-4">
                <button class="btn btn-primary" (click)="back()">Back</button>
            </div>
        </div>

    </div>
</div>
