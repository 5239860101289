import {Routes, RouterModule} from '@angular/router';

import {HomeComponent} from './home';
import {SearchComponent} from "./search/search.component";
import {SearchResultsComponent} from "./search-results/search-results.component";
import {BrowserSupportComponent} from "./browser-support/browser-support.component";
import {FileSearchComponent} from "./file-search/file-search.component";
import {FileSearchDetailsComponent} from "./file-search-details/file-search-details.component";
import {EventSearchComponent} from "./event-search/event-search.component";
import {ErrorComponent} from './error/error.component';
const routes: Routes = [
  {
    path: 'unsupported-browser',
    component: BrowserSupportComponent
  },
  {
    path: 'portal',
    component: HomeComponent
  },
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'NA/search',
    component: SearchComponent
  },
  {
    path: 'NA/search-results',
    component: SearchResultsComponent
  },
  {
    path: 'NA/file-search',
    component: FileSearchComponent
  },
  {
    path: 'NA/file-search-details',
    component: FileSearchDetailsComponent
  },
  {
    path: 'NA/event-search',
    component: EventSearchComponent
  },
  {
    path: 'error',
    component: ErrorComponent
  },

  {path: '', redirectTo: 'portal', pathMatch: 'full'},
  {path: '**', redirectTo: 'error'}
];

export const AppRoutingModule = RouterModule.forRoot(routes);
